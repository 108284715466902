.dataTableHeader{
    background-color: #61B043!important;
}
#filtrBtn{
    margin-left: 60vw;
}
@media  screen and (min-width: 467px) {
    #Mob_Card{
        display: block!important;
      } 
}
.Price{
    text-align: right;
    color: #61B043;
    font-weight: 800;
    font-size: 18px;
}
@media screen and (max-width: 467px){
.container-fluid h2 {
    margin-top: 2rem!important;
}
.desktopView{
    display: none!important;
}
#filterbt{
    margin-bottom: 1rem!important;
}
#filterContainer{
    margin-bottom: 10px;
}}

@media screen and (min-width: 467px){ 
#Mob_Card{
    display: none!important;
}}
@media screen and (max-width: 467px) {
        #filterbtn{
            display: none;
          }  }
@media screen and (min-width: 467px) {
        #filterbtn{
            display: block!important;
          } 
        #filtrBtn{
            display: none!important;
        }  
         }
 #filtrBtn{
        margin-top: -70px;
    }
#Mob_Card{
    margin-bottom: 1vh;
    border: solid black 0.1rem;
   }    
#Mob_Card .card-body{
    padding:0 1rem;
} 

.Location {
    padding: 0px!important;
  }

#remove{
  margin-bottom: 20px;
}
