.report-table {
    table-layout: fixed;
    width: 100%;
}
.report-table__header {
    padding: 0.1rem;
    /* margin: 0.5rem; */
    border-bottom: 0.1rem solid black;
}
.report-table__cell {
    vertical-align: justify;
    text-align: center;
}

.cell_width_commodity {
    width: 10%;
}

.cell_width_type {
    width: 15%;
}

.cell_width_date {
    width: 18%;
}

.cell_width_name {
    width: 57%;
}

.cell_width_desc {
    width: 10%;
}

.report_file_link {
    color: #0d6efd;
}

.report-table-container {
    height: 70vh;
    overflow: scroll;
}