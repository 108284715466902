.report-table {
    table-layout: fixed;
    width: 100%;
}
.report-table__header {
    padding: 0.1rem;
    /* margin: 0.5rem; */
    border-bottom: 0.1rem solid black;
}
.report-table__cell {
    vertical-align: justify;
    text-align: center;
}

.cell_width_commodity {
    width: 10%;
}

.cell_width_type {
    width: 20%;
}

.cell_width_date {
    width: 20%;
}

.cell_width_name {
    width: 40%;
}

.cell_width_desc {
    width: 10%;
}

.report_file_link {
    color: #61b043;
}

.report-table-container {
    height: 70vh;
    overflow: scroll;
}

#ArchivesHeading{
    color: #61b043;
  }
.SubmitBtn.btn.btn-primary ,.ClearBtn.btn.btn-primary , .btn.btn-primary
    {
    background-color: #61b043!important;
    border-color: #61b043!important;
}  
