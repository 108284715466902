@media screen and (max-width: 467px) {
.PcMarketUpdate{
    display: none!important;
    
}
.PcMarketUpdate.col-9{
    display: none!important;
}
.MobileFilter{
    display: block;
}
#PcFilter{
    display: none!important;
}
#MobileFilter{
    width: 90vw!important;
}
#filterBtn{
    margin-left: 60vw;
}
p , text{
    font-size: 0.8rem;
  
}

}
h2{
    color:  #61b043;
}

@media screen and (min-width: 467px) {
/* .PcMarketUpdate{ */
    /* background-color: red; */

/* } */
/* .MobileMarketUpdate{
    display: none!important;
} */
}
/* .MobileReportCard{
    /* display: block!important; */
    /* color:yellow; 

} */
#MobileReportCard{
    /* margin-top: -8vh; */
    width: 96%;
    margin-left: 0.5rem;
    margin-right: 1rem;
} 

.NewsHead{
    margin-top: 20px!important;
}