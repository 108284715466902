
.latestReportSection {
    height: 80vh;
    overflow-y: auto;
    width: 100%;
}

.reportCollectionRow {
    margin-bottom: 5vh;
}

.LatestReport_Heading {
    padding: 0vh;
}
/* .Card{
    margin-Bottom:'4.5rem';
    margin-Top: '-2rem';
} */


@media screen and (max-width: 467px) {
.table.table-sm{
    display: none;
  } 
.latestReportSection.row{
    width: 100vw;
}
.MobileReportsTable{
    display: none;
}
#filterBtn{
    margin-left: 67vw;
    margin-top: -6vh;
    margin-bottom: 5rem;
    
}
#MobileReportCard{
    margin-top: -9vh;
    width: 100%;
}

    .ReportType
    .PublishDate{
      font-size: 12px;
    }
    b{
      font-size: 14px;
    }.reportCollectionRow{
      margin-bottom: 10px!important;
    }
    .MobileCardBody{
      padding-bottom: 0rem!important;
      padding-top: 0rem!important;
    }
    .downloadBtn.btn.btn-primary{
      margin-right: 1rem;
      margin-top: 0.5rem;
      /* flex-direction: 'row-reverse' ;
      margin-top: '-3rem'; */
    }
    .downloadRow , .downloadRow.row{
      flex-direction: row-reverse ;
       margin-top: -3rem;
    }
    .MobileCard{
      display: block!important;  
    }
    .MobileCard.card{
      margin-bottom: 1.5rem;
      border: 1px!important;
      border-style: solid!important;
      /* border-radius: 2rem; */
      /* border: 4px; */
      /* margin-top: -2rem; */
    }
    .filterBtn{
      display: inline-block!important;
    }
    /* .filterArchives{
      display: none;
    } */
    h3{
      font-size: 16px;
    }
    
    .DesktopTable{
      display: none!important;
    }
    /* th , td{
      display: none;
    } */
    .LatestReport_Heading.row{
      margin-top: 0.5rem;
    }
    /* .report-table__header{
      display: none;
    } */
    /* tr{
      display: none;
    } */
    /* .table.table-sm{
      display: none;
    } */
    .carbody2
    .table.table-sm{
      display: block!important;
    
}
.ReportType
.PublishDate{
  font-size: 12px;
}
b{
  font-size: 14px;
}.reportCollectionRow{
  margin-bottom: 10px!important;
}
.MobileCardBody{
  padding-bottom: 0rem!important;
  padding-top: 0rem!important;
}
.downloadBtn.btn.btn-primary{
    margin-right: 1rem;
    margin-top: 0.5rem;
    /* flex-direction: 'row-reverse' ;
    margin-top: '-3rem'; */
  }
  .downloadRow , .downloadRow.row{
    flex-direction: row-reverse ;
     margin-top: -3rem;
  }
  .MobileCard{
    display: block!important;  
  }
  .MobileCard.card{
    margin-bottom: 1.5rem;
    border: 1px!important;
    border-style: solid!important;
    /* border-radius: 2rem; */
    /* border: 4px; */
    /* margin-top: -2rem; */
  }
  .filterBtn{
    display: inline-block!important;
  }
  /* .filterArchives{
    display: none;
  } */
  h3{
    font-size: 16px;
  }
  
  .DesktopTable{
    display: none!important;
  }
  /* th , td{
    display: none;
  } */
  .LatestReport_Heading.row{
    margin-top: 0.5rem;
  }
  /* .report-table__header{
    display: none;
  } */
  /* tr{
    display: none;
  } */
  /* .table.table-sm{
    display: none;
  } */
  .carbody2
  .table.table-sm{
    display: block!important;
  }
  .ReportType.col , .PublishDate.col{
    font-size: 13px;
  }
  
}
.HeadingReport{
  color: #61b043!important;
  
}
.mobRepCard_Col_2 button{
background-color: #61b043!important;
}

.report_file_link{
  cursor: -webkit-grab; cursor: pointer ;
}
.report-table__cell.cell_width_desc
.btn.btn-info.btn-sm{
  background-color:#61b043;
  border-radius: 25px;
  border-color: #61b043;
}