.header_container {
    /* background-color: rgb(60, 141, 188); */
    padding-top: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
    margin: 0rem;
    /* z-index: 5; */
}

.header_navbar {
    width: 100%;
    margin: 0rem;
    padding: 0rem;
    /* background-color: rgb(0, 255 ,255); */
}

.navbar_brand {
    margin-left: 2rem;
}

.header_container.container-fluid{
    position: fixed;
    z-index: 1;
    background-color: #f6f6f6;
   
}
img{
    width: 70px;
    /* height: 50px; */
}
@media screen and (max-width: 467px){
.dashboard-main-header.row
.btn.btn-primary{
  /* color: Red; */
  position: fixed;
  left: 6px;
  top:  6px;
}
.navbar_brand.navbar-brand
{
  position: fixed;
  right: 6px;
  top: 6px;

}
.header_container.container-fluid{
  height: 4rem;
}


/* Filter */
#PcFilter{
  display: none;
}
#MobileFilter{
  display: none;
}
#ArchivesHeading{
  margin-top: 1rem!important;
}



}
.dashboard-main-content{
    background-color: white!important;
    /* margin-top: 5rem; */
  }
  .dashboard-main-footer{
    background-color: white!important;
    /* margin-top: 5rem; */
  }


.LogoutBtn{
background-color: #61b043!important;
text-align: left!important;
border-color: #61b043!important;
/* margin-left: 10px!important; */
font-size: 1.2rem!important;
}
.HeadingReport{
  margin-top: 0.5rem!important;
}
