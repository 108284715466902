
.sidebar_menuField_primary {
    width: 100%;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: 0.05rem solid black;
}

.sidebar_navItemOnHover {
    background-color: #61b043;
    font-weight: bold;
}

.sidebar_navItem {
    text-align: left;
    vertical-align: middle;
    width: 100%;
    color: #fff;
    padding: 0.2rem;
}

.sidebar_navLabel {
    color: #fff;
    margin-left: 1rem;
    padding-left: 0.5rem;
    font-size: 1.2rem;
}

.sidebar_left_arrow_icon {
    margin-top: 0.5rem;
    float: right;
    color: #fff;
}

.sidebar_submenu {
    background-color: rgb(44, 60, 65);
}
/* .container-fluid */
/* .LogoutBtn.btn.btn-primary{
    display: none;
} */