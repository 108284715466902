
.dashboard-main-header {
    height: 5vh;
}
.dashboard-main-content {
    padding-top: 3vh;
    padding-bottom: 3vh;
    height: 90vh;
    background-color: #61b043;
}
.dashboard-main-footer {
    height: 5vh;
    background-color: #61b043;
}
.dashboard-content-area {
    padding: 2rem;
}
.dashboard-main-content{
    background-color: white!important;
    /* margin-top: 0rem; */
  }
  .dashboard-main-footer{
    background-color: white!important;
    /* margin-top: 5rem; */
  }
@media screen and (min-width: 467px) {
    #filterBtn{
      display: none;
    }
    #MobileFilter{
      display: none;
    }
    }
    
    @media screen and (max-width: 467px) {
    #FormEmail{
      width: 78vw;
    }
    .FormLabel{
      width: 78vw;
    }
    #FormOrganization , #FormNewPassword{
      width: 78vw;
      margin-bottom: 1rem;
    }
    .dashboard-main-content.row{
      height: auto;
    }
    .submitBtn.btn.btn-primary{
      width: 16.5rem;
      background-color: #61b043!important;
     }
    .dashboard-main-content{
      height: 100vh!important;
    }
    .SubmitBtn , .ClearBtn{
      width: 25vw!important;
    }
    .container-fluid
    h2{
    margin-top: 3rem;
    }
    .ReportType.col , .PublishDate.col{
      font-size: 13px;
    }
    .dashboard-main-header.row
    .btn.btn-primary{
      position: fixed;
      left: 6px;
      top:  6px;
    }
    .navbar_brand.navbar-brand
    {position: fixed;
        right: 6px;
        top: 6px;
    }
    .header_container.container-fluid{
      height: 4rem;
    }
    #PcFilter{
      display: none;
    }
    #MobileFilter{
      display: none;
    }
    #ArchivesHeading{
      margin-top: 1rem!important;
    }
    }
    .card1 , .card2{
      margin-top: 2rem!important;
    }
    /* submitBtn btn btn-primary */
    .submitBtn.btn.btn-primary{
      /* width: 16.5rem; */
      background-color: #61b043!important;
      border-color: #61b043!important;
    }
    @media screen and (max-width: 591px) {
    .dashboard-main-content{
      background-color: white!important;
      margin-top: 0rem;
    }
  }