.mobRepCard_Col_1 {
    width: 80%!important;
}

.mobRepCard_Col_2 {
    width: 20%!important;
}

.ReportType{
    font-weight: 800;
}
@media  screen and (min-width: 467px) {
    .MobileReportCard.card{
        display: none!important;
      }      
}
@media  screen and(max-width:467) {
    .MobileCardDiv{
        margin-top: 10rem;
    }
    
}

