
.footer_container {
    background-color:#61b043;
    text-align: center;
    /* margin-top: 4rem!important; */
    /* position:fixed; */
}

.footer_content {
    color: #000;
    margin-top: 0.5rem;
    font-weight: bold;
    
}
.dashboard-main-content{
    background-color: white!important;
    /* margin-top: 5rem; */
  }
  .dashboard-main-footer{
    background-color: white!important;
    /* margin-top: 5rem; */
  }
@media screen and (max-width: 467px) {
    .dashboard-main-footer.row{
      position: fixed;
      /* top: 4rem; */
      bottom: 0;
      z-index: 0;
      height: 3.5rem;}
    /* .col-9{
      display: block;
      overflow-x: auto;
      white-space: nowrap;
      margin-bottom: 4rem;
    }  */
    /* .dashboard-main-footer.row
    .latestReportSection.row{
      margin: 10rem;
      position:fixed;
    } */
    .card1{
      margin-top: 5rem;
      margin-bottom: 5rem;
    }
    .card2{
      margin-bottom: 10rem;
    }
    .cardbody1.card-body
    {
      width: 97vw;
    }
    .btn.btn-primary{
      height: 6vh;
      /* width: 26vw; */
    }
    .footer_container.container-fluid{
      width: 100vw;
    }
    }
    .footer_container.container-fluid{
        position: fixed;
    }