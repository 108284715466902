
.editModalBody {
    height: 80vh;
    overflow: scroll;
}

.selectAllCheckbox {
    padding: 1rem;
}

.dataTableContainer {
    height: 70vh;
    overflow: scroll;
}

.dataTableHeader {
    position: sticky;
    top: 0rem;
    background-color: rgb(90, 212, 161);
}


